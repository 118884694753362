import React, { useState } from "react";
import "./Review.css";

const testimonials = [
  { quote: "My health has significantly improved!...", author: "ARIA T", stars: "★★★★★" },
  { quote: "I finally feel understood and valued...", author: "Liam G", stars: "★★★★★" },
  { quote: "Amazing experience from start to finish...", author: "Sophie R", stars: "★★★★★" },
  { quote: "The quality of care I received...", author: "Jacob H", stars: "★★★★★" },
  { quote: "The team is phenomenal!...", author: "Emily K", stars: "★★★★★" },
];

const TestimonialSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const goToPrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="testimonial-container">
      <h2 className="testimonial-title">What Our Clients Say</h2>
      <div className="testimonial-slider">
        <div className="testimonial-card">
          <p className="testimonial-quote">"{testimonials[currentIndex].quote}"</p>
          <h3 className="testimonial-author">{testimonials[currentIndex].author}</h3>
          <p className="testimonial-stars">{testimonials[currentIndex].stars}</p>
        </div>
      </div>
      <div className="slider-controls">
        <button className="slider-button prev" onClick={goToPrev}>
          &lt;
        </button>
        <button className="slider-button next" onClick={goToNext}>
          &gt;
        </button>
      </div>
    </div>
  );
};

export default TestimonialSlider;
